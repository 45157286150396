<template>
  <ClientOnly>
    <template #fallback>
      <div class="rounded-lg w-[100px] h-[36px] bg-gray-100 animate-pulse"></div>
    </template>
    <Menu as="div" class="relative block text-left">
      <div class="flex justify-center items-center">
        <MenuButton class="lb-nav-item-rgt !flex">
          <div class="btn-user" :class="color">
            <!--          <slot name="icon">-->
            <!--            <LucideUserCircle class="h-5 w-5 sm:h-4 sm:w-4" />-->
            <!--          </slot>-->
            <slot />
          </div>
          <div class="flex p-2.5 lg:hidden">
            <LucideUserCircle class="h-5 w-5 sm:h-4 sm:w-4" />
          </div>
        </MenuButton>
      </div>

      <transition
          enter-active-class="transition duration-200 ease-out"
          enter-from-class="translate-y-1 opacity-0"
          enter-to-class="translate-y-0 opacity-100"
          leave-active-class="transition duration-150 ease-in"
          leave-from-class="translate-y-0 opacity-100"
          leave-to-class="translate-y-1 opacity-0"
      >
        <MenuItems class="lb-dropdown-wrapper">
          <div class="absolute bottom-full w-6 right-6 md:left-1/2 md:-translate-x-1/2 overflow-hidden inline-block">
            <div class=" h-4 w-4 bg-white border rotate-45 transform origin-bottom-left"></div>
          </div>

          <!-- B2B Firma Bilgileri -->
          <div class="px-2 py-3 border-b" v-if="data?.company_name && data?.customer_type === 1">
            <div class="text-xs font-medium">
              {{data.company_name}}
            </div>
            <div class="text-xs text-gray-600" v-if="data.identity">VKN : {{data.identity}}</div>
          </div>

          <div class="py-1">
            <MenuItem v-for="item in menu" :key="item.link">
              <NuxtLinkLocale class="lb-dropdown-item" :to="checkAndAddSlash(item.link)">
                <component :is="item.icon" class="h-4 w-4 mr-3"/>
                {{ $t(item.title) }}
              </NuxtLinkLocale>
            </MenuItem>

            <MenuItem @click="signOut">
              <button type="button" class="lb-dropdown-item w-full">
                <LucidePower class="h-4 w-4 mr-3"/>
                {{ $t('auth.sign-out') }}
              </button>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </Menu>
  </ClientOnly>
</template>

<script setup>
import {Menu, MenuItems, MenuItem, MenuButton} from "@headlessui/vue";
import {collect} from "collect.js";

import { Settings, Inbox, MapPin, Heart, MessageCircle, FileText, CoinsIcon } from 'lucide-vue-next';

const props = defineProps(['color'])

// Menü içeriği
let menu = collect([
  {title: "user-menu.account-information", link: "/account/user", icon: Settings},
  {title: "user-menu.orders", link: "/account/orders", icon: Inbox},
  {title: "user-menu.addresses", link: "/account/address", icon: MapPin},
  {title: "user-menu.wishlists", link: "/account/wishlist", icon: Heart},
]);

const storeConfig = useStoreConfig()
const {isB2BAccount} = storeToRefs(storeConfig)

const {signOut, data} = await useAuth()
const {isWholesaleUser} = await useCustomer()
const {showOnlyB2C} = useHelper()

if (showOnlyB2C.value && menu.where('link', '=', 'review').isEmpty()) {
  menu.push({
    title: "user-menu.reviews", link: "review", icon: MessageCircle
  })
}

if (menu.where('link', '=', '/checkout/payment').isEmpty() && isB2BAccount.value && isWholesaleUser.value) {
  menu.push({
    title: "user-menu.account-payment", link: "/checkout/payment", icon: CoinsIcon
  })
}

if (menu.where('link', '=', 'statement').isEmpty() && isB2BAccount.value && isWholesaleUser.value) {
  menu.push({
    title: "user-menu.statements", link: "/account/statement", icon: FileText
  })
}

</script>

<style lang="scss">
.btn-user {
  @apply bg-gray-50 border-2 border-gray-200 px-5 py-2 items-center justify-center rounded-lg hidden lg:flex text-primary hover:text-secondary gap-2.5;
}
</style>